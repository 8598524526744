import axios, { AxiosError } from 'axios'
import { STEP_FAMILY_INFORMATION, STEP_UNLOCK, STEP_FINANCE, STEP_SIGNING, STEP_UPLOADING_DOCUMENTS, STEP_COMPLETED_SAVED, STEP_COMPLETED_SENT_TO_CUSTOMER, STEP_OFFICE_PROCESSED_REFERRED, STEP_CONFIRM_EMAILS_RECEIVED, STEP_LTL_VIDEO_CONFIRMATION, STEP_COMPLETED_NORMAL, MESSAGEBIRD_SENDER_FOR_NUMBER, STEP_CANCELLED_ACCOUNT, REFUND_EMAIL_TO, STEP_CHOOSE_SESSIONS } from '@iris/constants'
import { ActionContext } from 'vuex'
import { IrisState, IrisStore, IrisGetters } from './types'
import { DocumentDataWithoutSigners, InvalidPhoneNumberError } from '@iris/api'
import { cancelDeferredPayments } from '@iris/deferred-admin'
// import { assessmentsFeathersApi } from '@iris/assessments'
import i18n from '@iris/i18n'
import { MessageType, SendSalesConfirmationEmailAndSmsDocument, SendSalesConfirmationEmailAndSmsMutation, SendSalesConfirmationEmailAndSmsMutationVariables, SendSmsToCustomerDocument, SendSmsToCustomerMutation, SendSmsToCustomerMutationVariables } from '@iris/queries'

interface IrisActionContext extends ActionContext<IrisState, IrisState> {
  getters: IrisGetters
}
type IrisAction<S, R> = (this: IrisStore, context: IrisActionContext, payload?: any) => any;
interface IrisActionTree<S, R> {
  [key: string]: IrisAction<S, R>;
}

const apiActions = (irisDocumentsBaseUrl: string): IrisActionTree<IrisState, IrisState> => ({
  async createOrUpdateFamilyAccount ({ state, commit, getters, dispatch }) {
    if (!state.submitting && state.step === STEP_FAMILY_INFORMATION) {
      try {
        commit('submitting')
        if (state.createAccountResult === null) {
          try {
            if (state.bypassPayment) {
              await dispatch('createFamilyAccountAfterUnlock')
            } else {
              // creating a new account - verify mobile phone number first
              commit('updateField', { path: 'flashMessage', value: `Sending Unlock Code` })
              let result = await this.irisApi.generateUnlockCode(getters.dataForGenerateUnlockCodeApi)
              commit('submitSuccess', STEP_UNLOCK)
              if (!result.codeSent) { // failed somehow to send unlock code just create account without unlock
                await dispatch('createFamilyAccountAfterUnlock')
              }
            }
          } catch (exception) {
            if (exception instanceof InvalidPhoneNumberError) {
              commit('submitFail', `Unable to send unlock code: ${exception.message}`)
            } else {
              commit('submitFail', `Failed to send unlock code: ${exception}`)
              throw exception
            }
          }
        } else {
          try {
            // we are updating the information
            commit('updateField', { path: 'flashMessage', value: `Updating family information` })
            let result = await this.irisApi.updateAccount(getters.dataForUpdateAccountApi)
            commit('updateField', { path: 'flashMessage', value: null })
            if (result.isUpdate) {
              commit('submitSuccess', STEP_FINANCE)
              commit('removedSavedFamilyInfo') // always attempt to remove
            } else {
              commit('submitFail', result.message)
            }
          } catch (exception) {
            commit('submitFail', `Failed to update family information: ${exception}`)
            throw exception
          }
        }
      } catch (exception) {
        commit('apiError', exception)
      }
    }
  },
  async updateAndResendAccountEmails ({ commit, state }, newEmail) {
    if (!state.submitting) { // stop double calls to this
      try {
        commit('submitting')
        commit('updateField', { path: 'flashMessage', value: `Resending welcome email to ${newEmail}` })
        let result = await this.irisApi.updateAndResendAccountEmails({ subscriberId: state.createAccountResult!.subscriberId, email: newEmail })
        if (result.status) {
          // update email
          commit('updateField', { path: 'familyInformation.email', value: newEmail })
          commit('updateField', { path: 'familyInformation.emailConfirm', value: newEmail })
          commit('submitSuccess', null) // don't go anywhere
          commit('updateField', { path: 'submitMessage', value: `Welcome emails have been sent to ${newEmail}` })
        } else {
          commit('submitFail', `Failed to resend emails: ${result.message}`)
        }
      } catch (exception) {
        commit('apiError', exception)
        commit('submitFail', `Failed to update family email address: ${exception}`)
      }
    }
  },
  async resendUnlockCode ({ state, commit, getters, dispatch }) {
    commit('updateField', { path: 'flashMessage', value: `Resending unlock code to ${state.familyInformation.mobilePhone}` })
    try {
      commit('updateField', { path: 'flashMessage', value: `Resending Unlock Code` })
      let result = await this.irisApi.generateUnlockCode(getters.dataForGenerateUnlockCodeApi)
      commit('updateField', { path: 'flashMessage', value: null })
      commit('updateField', { path: 'submitMessage', value: `Unlock code resent to ${state.familyInformation.sendUnlockCodeAsPlainMessage === 'email' ? state.familyInformation.email : state.familyInformation.mobilePhone}` })
      if (!result.codeSent) {
        await dispatch('createFamilyAccountAfterUnlock')
      }
    } catch (exception) {
      commit('apiError', exception)
      commit('submitFail', `Failed to resend unlock code: ${exception}`)
    }
  },
  async unlockAccount ({ state, commit, getters, dispatch }) {
    if (!state.submitting && state.step === STEP_UNLOCK) {
      commit('submitting')
      try {
        commit('updateField', { path: 'flashMessage', value: `Checking unlock code` })
        let result = await this.irisApi.validateUnlockCode(getters.dataForUnlockApi)
        if (result.unlockAccount) {
          await dispatch('createFamilyAccountAfterUnlock')
        } else {
          commit('submitFail', 'Incorrect unlock code entered')
        }
      } catch (exception) {
        commit('apiError', exception)
        commit('submitFail', `Failed to validate unlock code: ${exception}`)
      }
    }
  },
  async createFamilyAccountAfterUnlock ({ commit, getters, state }) {
    commit('submitting')
    try {
      if (!state.subscriberId) {
        commit('updateField', { path: 'flashMessage', value: `Getting next subscriber id value` })
        const { nextSubscriberID } = await this.irisApi.getEstiaLabsNextSubid()
        commit('updateField', { path: 'subscriberId', value: nextSubscriberID })
      }
      commit('updateField', { path: 'flashMessage', value: `Creating account - Please wait this usually takes approximately 1 minute` })
      let createResult = await this.irisApi.createAccount(getters.dataForCreateApi)
      commit('updateField', { path: 'flashMessage', value: null })
      if (createResult.isCreate) {
        // if there is an assessments systemid push the time and subid back
        try {
          // if (state.assessmentId) {
          //   const assessmentsApi = assessmentsFeathersApi(this.irisApi)
          //   await assessmentsApi.service('assessments').patch(state.assessmentId, {
          //     subscriberId: createResult.subscriberId,
          //     saleDate: createResult.accountCreationTime
          //   })
          // }
        } catch (e) {
          // probrably do nothing as it's not a major issue if this fails
          // eslint-disable-next-line no-console
          console.error(e)
        }
        // store result for next page
        commit('updateField', { path: 'createAccountResult', value: createResult })
        commit('submitSuccess', STEP_CONFIRM_EMAILS_RECEIVED)
      } else {
        // go back to 1st screen & Display message
        commit('updateField', { path: 'step', value: STEP_FAMILY_INFORMATION })
        commit('submitFail', createResult.message)
      }
    } catch (exception) {
      commit('apiError', exception)
      commit('submitFail', `Failed to create account: ${exception}`)
    }
  },
  /** setup recurly account if it exists */
  setupRecurlyAccount ({ commit, state, getters }) {
    if (!state.recurlyAccountId) {
      return this.nestApi.createRecurlyAccount(getters.dataForRecurlyCreateAccount).then((resp) => {
        commit('updateField', { path: 'recurlyAccountId', value: resp.accountId })
        return resp.accountId
      })
    }
    return Promise.resolve(state.recurlyAccountId)
  },
  async sessionsChosen ({ commit, dispatch }) {  
    // clear the saved data
    commit('updateField', { path: 'flashMessage', value: null })
    commit('submitSuccess', STEP_COMPLETED_NORMAL)
    await dispatch('logout')
  },
  // this is called after the finance step, it may also just finish iris due to the state.finance.decision === 'FD'
  async createDocuments ({ commit, getters, dispatch, state }, recurlyNoBillingInfo: boolean = false) {
    if (!state.submitting && state.step === STEP_FINANCE) {
      commit('submitting')
      try {
        // finalise any payments now
        commit('payments/finalisePayments')
        // email off any finalised refund types
        await Promise.all(state.payments.payments.filter(p => p.type === 'manual-refund' && p.finalised).map(p => {
          return this.$feathers.service('email').create({
            context: {
              preview: `Refund required to be processed for ${i18n.n(p.amountInCents / -100, 'currency')}`,
              refundAmount: i18n.n(p.amountInCents / -100, 'currency'),
              table: {
                'Consultant': state.consultant,
                'Programme Type': state.finance.paymentMethod,
                'Subscriber ID': state.createAccountResult!.subscriberId,
                'Amount': i18n.n(p.amountInCents / -100, 'currency'),
                'Surname': state.familyInformation.lastName,
                'Post Code': state.address.postcode,
                'Email': state.familyInformation.email,
                'Office': state.branchOffice
              }
            },
            to: REFUND_EMAIL_TO,
            subject: `Refund required for ${state.familyInformation.lastName} ${state.address.postcode}`,
            template: 'iris/refund'
          })
        }))
        if (state.finance.decision === 'FR') {
          // this now simply creates 1 document to sign next step will store this data.
          // this will simply store the data to the server
          commit('updateField', { path: 'finance.referred', value: true })
          // rest of this code is now part of uploadDocuments action
        }
        // is stripe handling direct debits
        if (getters.isStripeDirectDebit) {
          commit('updateField', { path: 'flashMessage', value: `Saving payment Details` })
          await dispatch('instalments/createScheduledSubscription')
          commit('updateField', { path: 'flashMessage', value: null })
        }
        // this will simply generate the correct document pack
        commit('updateField', { path: 'flashMessage', value: `Sending Account Details` })
        let updateFinanceResult = await this.irisApi.updateFinanceData(getters.dataForFinanceApi)
        commit('updateField', { path: 'flashMessage', value: null })
        if (updateFinanceResult.status) {
          if (state.documentationOnlyMode) {
            // clear documents only if resigning
            commit('clearAllDocuments')
          }
          if (state.recurlyDirectDebit && !state.documentationOnlyMode && getters.isSubscriptionPlanDec2021) {
            commit('updateField', { path: 'flashMessage', value: `Setting up automatic payments` })
            commit('updateField', { path: 'recurlyApiLastError', value: null })
            try {
              await dispatch('setupRecurlyAccount')
              const response = await this.nestApi.setupRecurlyDirectDebit({
                ...getters.dataForRecurlyCreate,
                // remove billing information if bypass is used - basically null out billingInfo
                ...(recurlyNoBillingInfo ? {
                  billingInfo: undefined
                } : {})
              })
              // store response
              commit('updateField', { path: 'recurlyByPassUsed', value: recurlyNoBillingInfo })
              commit('updateField', { path: 'recurlyResponse', value: response })
            } catch (e) {
              const axiosError: AxiosError = e
              let message: string
              if (axiosError.response && axiosError.response.status === 400 && axiosError.response.data.message) {
                message = Array.isArray(axiosError.response.data.message) ? axiosError.response.data.message.join(' and ') : axiosError.response.data.message
              } else {
                message = e.message || e
              }
              commit('updateField', { path: 'recurlyApiLastError', value: message })
              commit('submitFail', 'Failed to save direct debit information (see details below)')
              return
            }
            commit('updateField', { path: 'flashMessage', value: null })
          }
          await dispatch('setCorrectDocumentsOnState')
          commit('updateField', { path: 'flashMessage', value: `Sending text message to customer to accept` })
          await this.nestApi.apolloClient().mutate<SendSmsToCustomerMutation, SendSmsToCustomerMutationVariables>({
            mutation: SendSmsToCustomerDocument,
            variables: {
              data: getters.dataForConfirmationApi
            }
          }).then(result => {
            commit('updateField', {
              path: 'newSigningProcessConfirmationId',
              value: result.data ? result.data.salesConfirmationInit.id : null
            })
            commit('submitSuccess', STEP_SIGNING) // will set flashMessage to null
            if (state.bypassPayment) {
              return dispatch('signDocumentsCompleted')
            } else {
              // update flash message depending on if sms failed to send to notify if email was used
              if (result.data && result.data.salesConfirmationInit.messageType === MessageType.Email) {
                commit('updateField', { path: 'flashMessage', value: `SMS Failed to send please check ${result.data.salesConfirmationInit.email} for link` })
              }
            }
          })
        } else {
          // didn't save? no idea why show reason leave client where it is
          commit('submitFail', updateFinanceResult.message)
        }
      } catch (exception) {
        commit('apiError', exception)
        commit('submitFail', `Failed to send Financial Details: ${exception}`)
      }
    }
  },
  /**
   * Note this will jump to video step if enabled and need to call again at video step to actually upload documents
   */
  async signDocumentsCompleted ({ commit, dispatch, state, getters }, { pdfBlobPromises = {} } = {}) {
    if (state.step === STEP_SIGNING || state.documents.every((d) => !d.uploading)) {
      commit('submitSuccess', STEP_UPLOADING_DOCUMENTS) // assume successful here progress will be shown on next page
      if (getters.finalSaleType !== 'LTL') {
        // append the completion cert to the end of documents only if not going to confirmation page
        // this will return null but the getter will create the pdf blob if required
        pdfBlobPromises['COMPLETION_CERTIFICATE'] = await dispatch('generateCompletionCertificate')
      }
      // map needs to occur before filter
      const documentsToUpload = state.documents.map((doc, index) => {
        // every doc will be upload if extra confirmations is disabled
        // if it's enabled then every doc apart from completion cert will be uploaded
        if (!doc.url && (getters.finalSaleType !== 'LTL' || doc.id !== 'COMPLETION_CERTIFICATE')) {
          return { index, blobPromise: pdfBlobPromises[doc.id] }
        }
      }).filter(doc => !!doc)
      let uploadDocuments: Promise<any>
      if (documentsToUpload.length > 0) {
        uploadDocuments = dispatch('uploadDocumentsList', documentsToUpload)
      } else {
        uploadDocuments = dispatch('uploadDocumentsFinish') // no more documents to upload
      }
      await Promise.all([dispatch('payments/forceUpdateDirectDebitLogging'), uploadDocuments])
    }
  },
  async uploadDocumentsList ({ dispatch, commit }, documentsList: {index: number, blobPromise: Promise<Blob>}[]) {
    // upload the list of documents in sequence
    // force uploading flag for each document in the list
    for (let document of documentsList) {
      commit('uploadDocumentStart', { index: document.index })
    }
    for (let document of documentsList) {
      try {
        await dispatch('uploadDocument', document)
      } catch (e) {
        // eat exceptions it is ok as UI already handles it.
      }
    }
  },
  /**
   * This is called from 2 places
   * A) The normal irisUI flow so user is currently in UPLOAD UI part and on error needs to be moved back a step to allow retry
   * B) the referred finance decision in the office and docs are actually already uploaded and the user simply needs to just show same step again
   */
  async uploadDocumentsFinish ({ commit, state, getters, dispatch }) {
    if (state.submitting) {
      return
    }
    // should be in step=5 this is only on main iris ui
    // should be step=10 in referred finance decision
    if (state.step === STEP_UPLOADING_DOCUMENTS && getters.finalSaleType === 'LTL' && !state.videoConfirmation.accept) { // last condition is checked box so skip this
      commit('submitSuccess', STEP_LTL_VIDEO_CONFIRMATION)
    } else if (state.step === STEP_UPLOADING_DOCUMENTS || state.step === STEP_OFFICE_PROCESSED_REFERRED) {
      commit('submitting')
      try {
        if (state.finance.decision === 'FR') {
          // only step = 5 (STEP_UPLOADING_DOCUMENTS) when in here.
          commit('updateField', { path: 'flashMessage', value: 'Saving Account Details' })
          let storeIncompleteFinanceResult = await this.irisApi.storeIncompleteFinance(getters.dataForStoreIncompleteFinanceApi)
          commit('updateField', { path: 'flashMessage', value: null })
          if (storeIncompleteFinanceResult.status) {
            commit('submitSuccess', STEP_COMPLETED_SAVED) // skip over some steps
            // clear the saved data
            await dispatch('logout')
          } else {
            // didn't save? no idea why show reason leave client where it is
            commit('updateField', { path: 'step', value: STEP_SIGNING })
            commit('submitFail', storeIncompleteFinanceResult.message)
          }
        } else {
          commit('updateField', { path: 'flashMessage', value: `Emailing documents - Please wait this usually takes approximately 1 minute` })
          let documentApiResult = await this.irisApi.updateDocuments(getters.dataForDocumentsApi)
          if (documentApiResult.status) {
            await this.nestApi.apolloClient().mutate<SendSalesConfirmationEmailAndSmsMutation, SendSalesConfirmationEmailAndSmsMutationVariables>({
              mutation: SendSalesConfirmationEmailAndSmsDocument,
              variables: {
                id: state.newSigningProcessConfirmationId!,
                email: state.familyInformation.email,
                firstName: state.familyInformation.firstName,
                lastName: state.familyInformation.lastName,
                altEmail: state.familyInformation.alternativeEmail || undefined,
                mandatePdfUrl: (() => {
                  const doc = getters.documents.find(d => d.shortId === 'DD')
                  if (doc) {
                    return doc.url
                  }
                  return undefined
                })()
              }
            })
            if (!state.documentationOnlyMode) {
              commit('updateField', { path: 'flashMessage', value: `Updating dynamics crm` })
              try {
                await this.nestApi.convertPresentation(getters.dataForCrmConversion, state.dynamicsPresentationId || undefined)
              } catch (e) {
                // eslint-disable-next-line no-console
                await dispatch('alert', `Unable to save to CRM: ${e.message}, Please let the office know of this`)
                // console.error(e)
                // TODO popup modal here just explaining it didn't quite work
                // await Vue.prototype.$bvModal.msgBoxOk(`Failed to save to crm: ${e}`)
              }
            }
            commit('submitSuccess', state.step === STEP_UPLOADING_DOCUMENTS ? STEP_CHOOSE_SESSIONS : STEP_COMPLETED_SENT_TO_CUSTOMER)
          } else {
            // go back to documents page step = 4 if in uploading step
            if (state.step === STEP_UPLOADING_DOCUMENTS) {
              commit('updateField', { path: 'step', value: STEP_SIGNING })
            }
            commit('submitFail', documentApiResult.message)
          }
        }
      } catch (exception) {
        commit('apiError', exception)
        // go back to documents page step = 4 if in uploading step
        if (state.step === STEP_UPLOADING_DOCUMENTS) {
          commit('updateField', { path: 'step', value: STEP_SIGNING })
        }
        commit('submitFail', `Failed to finish account creation: ${exception}`)
      }
    }
  },
  async videoConfirmationFinish ({ commit, state, dispatch, getters }) {
    if (state.step === STEP_LTL_VIDEO_CONFIRMATION && !state.submitting) {
      commit('submitting')
      try {
        if (getters.ltlExtraConfirmations) {
          // UK fix for messagebird to use full international number for UK mobiles
          // if matches 07NNNNN then replace the 0 with a 44
          let mobilePhone = getters.ltlExtraConfirmationMobilePhone.replace(/^0(7\d+)$/, '44$1')
          commit('updateField', { path: 'flashMessage', value: `You will now receive your confirmation text` })

          await axios({
            url: 'https://flows.messagebird.com/flows/f19a3392-2dcc-4a58-863b-af7fde2e0800/invoke',
            method: 'POST',
            data: {
              recipient: mobilePhone,
              sender: MESSAGEBIRD_SENDER_FOR_NUMBER(mobilePhone)
            },
            headers: {
              'Content-Type': 'application/json'
            }
          })
        }

        commit('submitSuccess', STEP_UPLOADING_DOCUMENTS)

        // should only be completion cert left but this will also upload any other docs left over.
        let pdfBlobPromises: Record<string, Promise<Blob>> = {
          COMPLETION_CERTIFICATE: await dispatch('generateCompletionCertificate')
        }
        // map needs to occur before filter
        const documentsToUpoad = state.documents.map((doc, index) => {
          // every doc will be upload if extra confirmations is disabled
          // if it's enabled then every doc apart from completion cert will be uploaded
          if (!doc.url) {
            return { index, blobPromise: pdfBlobPromises[doc.id] }
          }
        }).filter(doc => !!doc)

        if (documentsToUpoad.length > 0) {
          await dispatch('uploadDocumentsList', documentsToUpoad)
        } else {
          dispatch('uploadDocumentsFinish')
        }
        // once all these complete the uploadDocumentsFinished will be called
      } catch (exception) {
        commit('apiError', exception)
        commit('submitFail', exception.message)
      }
    }
  },
  async genericDocumentUpload ({ state }, { name, blob, progressCallback }) {
    const source = axios.CancelToken.source()
    let timeoutId: number | null
    const watchdogFinish = () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
    const watchdog = (time = 30) => {
      watchdogFinish()
      timeoutId = window.setTimeout(() => {
        source.cancel(`Time out after ${time} seconds`)
        timeoutId = null
      }, time * 1000)
    }
    try {
      let url = `${irisDocumentsBaseUrl}${state.createAccountResult!.subscriberId}/${state.securekey}_${name}`
      watchdog(90) // start the dog allow 60 seconds to start to get first progress event
      await axios.put(url, blob, {
        headers: {
          'x-amz-acl': 'bucket-owner-full-control',
          'Content-Type': blob.type
        },
        onUploadProgress (progressEvent: ProgressEvent) {
          if (progressEvent.loaded === progressEvent.total) {
            // finished allow longer now
            watchdog(90)
          } else {
            watchdog() // default 30 seconds between progress events
          }
          progressCallback && progressCallback(progressEvent)
        },
        cancelToken: source.token,
        responseType: 'text',
        timeout: 10 * 60 * 1000 // 10 minutes
      })
      return url
    } finally {
      watchdogFinish()
    }
  },
  async uploadDocument ({ commit, state, getters, dispatch }, { index, blobPromise }: { index: number, blobPromise: Promise<Blob> }) {
    try {
      commit('uploadDocumentStart', { index })
      // blobPromise can just be null or return a nullish value so make sure it's a promise
      if (!await blobPromise) {
        blobPromise = getters.buildPdfDocumentBlobPromise(index)
      }
      commit('uploadDocumentProgress', { index, size: (await blobPromise).size, uploaded: 0, uploadProgress: 0 })
      let url = await dispatch('genericDocumentUpload', {
        name: `${state.documents[index].id}.pdf`,
        blob: await blobPromise,
        progressCallback (progressEvent: ProgressEvent) {
          commit('uploadDocumentProgress', { index, size: progressEvent.total, uploaded: progressEvent.loaded, uploadProgress: progressEvent.loaded / progressEvent.total })
        }
      })
      commit('uploadDocumentFinish', { index, url })
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception)
      commit('uploadDocumentError', { index, uploadError: exception.message })
    }
  },
  async sendDocuments ({ state }, { email, documents } : {email: string; documents: DocumentDataWithoutSigners[]}) {
    let result = await this.irisApi.sendDocuments({
      subscriberId: state.createAccountResult!.subscriberId,
      template: 'SENDPRECONTRACTEMAILCATEGORY',
      documents,
      email
    })
    if (!result.status) {
      throw new Error(result.message)
    }
  },
  async setCorrectDocumentsOnState ({ commit, getters }) {
    return commit('setDocuments', getters.documentPack.map(v => {
      return {
        uploadAt: null,
        url: null,
        uploadProgress: null,
        uploadError: null,
        uploading: false,
        id: v.id
      }
    }))
  },
  async logout ({ commit, state, dispatch }) {
    await dispatch('removePersistedKey')
    this.irisApi.stopWatchDog()
    if (state.authenticated && state.forceLogoutOnCompletion) {
      // set the authenticated to false
      commit('logout') // this will rewrite to localStorage again
      await dispatch('removePersistedKey')
      return this.irisApi.logout()
    }
  },
  async generateCompletionCertificate ({ commit, getters }) {
    let completionCertificateData = await this.irisApi.getCompletionCertificateData(getters.dataForCompletionCertificateDataApi)
    if (completionCertificateData.status) {
      commit('updateField', { path: 'completionCertificateData', value: completionCertificateData })
      // this doesn't need to return anything because the other method will create the blob
      return null
    } else {
      commit('updateField', { path: 'flashMessage', value: `Failed to get data for completion certificate ${completionCertificateData.message}` })
      throw new Error(completionCertificateData.message)
    }
  },
  async cancelReferredDeal ({ state, commit, getters, dispatch }) {
    // only called from REFERRED FINANCE PROCESS STEP = 10 only
    if (!state.submitting && state.step === STEP_OFFICE_PROCESSED_REFERRED) {
      commit('submitting')
      try {
        commit('updateField', { path: 'flashMessage', value: `Cancelling account` })
        let cancelStatus = await this.irisApi.cancelIncompleteAccount(getters.dataForCancelIncompleteAccountApi)
        commit('updateField', { path: 'flashMessage', value: `Cancelling any deferred payments` })
        await cancelDeferredPayments(this.$feathers, state.createAccountResult!.subscriberId)
        commit('updateField', { path: 'flashMessage', value: null })
        if (cancelStatus.status) {
          commit('submitSuccess', STEP_CANCELLED_ACCOUNT)
          // process sending documents to customer
          // clear the saved data and force logout
          await dispatch('logout')
        } else {
          commit('submitFail', cancelStatus.message)
        }
      } catch (exception) {
        commit('apiError', exception)
        commit('submitFail', `Failed to send documents: ${exception}`)
      }
    }
  },
  async sendDocumentsToCustomer ({ state, commit, getters, dispatch }) {
    // only called from REFERRED FINANCE PROCESS STEP = 10 only
    if (!state.submitting && state.step === STEP_OFFICE_PROCESSED_REFERRED) {
      commit('submitting')
      try {
        commit('updateField', { path: 'flashMessage', value: `Updating customers account details` })
        let completeFinanceStatus = await this.irisApi.completeFinanceDecline(getters.dataForCompleteFinanceDeclineApi)
        commit('updateField', { path: 'flashMessage', value: null })
        if (completeFinanceStatus.isUpdate) {
          // send documents to customer now
          // process the documents into the state
          commit('clearAllDocuments')
          await dispatch('setCorrectDocumentsOnState')
          commit('updateField', { path: 'flashMessage', value: `Sending documents to customer to sign` })
          let documentSendingResult = await this.irisApi.sendDocumentsToCustomer(getters.dataForSendDocumentsToCustomerApi)
          commit('updateField', { path: 'flashMessage', value: null })
          if (documentSendingResult.status) {
            commit('submitSuccess', STEP_COMPLETED_SENT_TO_CUSTOMER)
            // process sending documents to customer
            // clear the saved data and force logout
            await dispatch('logout')
          } else {
            commit('submitFail', documentSendingResult.message)
          }
        } else {
          commit('submitFail', completeFinanceStatus.message)
        }
      } catch (exception) {
        commit('apiError', exception)
        commit('submitFail', `Failed to send documents: ${exception}`)
      }
    }
  }
})

export default apiActions
