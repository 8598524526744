import Api, { IRequestGenerateUnlockCode, IRequestUpdateAccount, IResponseCreateAccount, IRequestUpdateDocuments, IRequestValidateUnlockCode, IRequestCreateAccount, IRequestUpdateFinanceData, IRequestCompleteFinanceDecline, IResponseCompletionCertificate, IRequestSendDocumentsToCustomer, IRequestStoreIncompleteFinance, IRequestCancelIncomplete } from '@iris/api'
import { IPdfDocumentDescription } from '@iris/assets/pdfs'
import { IPosition } from '@iris/components/GetLocation'
import { IrisPaygRevisionPricingOption, IrisPromoCodes, EstiaLabsPricingModel } from '@iris/constants'
import { ICourseIdPair, ICourseMap, IRegModelMap } from '@iris/regmodel'
import moment, { Moment } from 'moment-timezone'
import { Store } from 'vuex'
import Vue from 'vue'
import { PaymentModuleRootGetters, PaymentModuleState } from './payments/types'
import { Application } from '@feathersjs/feathers'
import { ServiceTypes } from '@iris/feathersjs'
import { InstalmentState, InstalmentModuleRootGetters } from './instalments'
import { IAccount, IIrisAccount, SubscriptionType, IAccountResponse } from '@iris/nestjs-interfaces'
import { NestJSApi } from '@iris/nestjs'
import { IAccountCreation } from '@iris/nestjs-interfaces-recurly'
import { SaleConfirmationData } from '@iris/queries'
export type CourseTypes = 'maths' | 'literacy' | 'mathsGCSE' | 'mathsN5' | 'reading';
/** payment periods */
export type PaymentPeriods = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
/** Plans with Payment methods types */
export type PlanWithPaymentPeriodsTypes = 'P' | 'G' | 'S' | 'B'
/** combination of all payment methods */
export type PaymentMethods = `EL${PlanWithPaymentPeriodsTypes}${PaymentPeriods}` | 'ELNM' | 'ELP12C';
export type DepositPaymentMethods = 'DEFERRED' | 'CREDITCARD' | 'CASH' | 'CHEQUE';
export type TStates = 'ENG' | 'WAL' | 'NIE' | 'SCO';
export type TAltPayerNames = 'CUSTOMER' | 'ALTPAYER' | 'DEPOSIT_ALT_PAYER' | 'DIRECT_DEBIT_ALT_PAYER';
export type TSignerNames = TAltPayerNames | 'CONSULTANT';
// alt payer values TODO change to altPayerNames above once refactor is completed
type TAltPayer = boolean;
export type TDepositAltPayer = TAltPayer | 'DEPOSIT_ALT_PAYER';
export type TDirectDebitAltpayer = TDepositAltPayer | 'DIRECT_DEBIT_ALT_PAYER';

/** Payg and APS Stripe plan deposit options */
export type TPaygMonthsDepositOptions = 1 | 2 | 3 | 6 | 12;

export interface IPdfVue extends Vue {
  load: () => Promise<void>,
  exportPDFBlob: () => Promise<Blob>
}
export interface IAddressInfo {
  address1: string;
  address2: string;
  city: string;
  postcode: string;
  country: 'GB';
  loqateResponse: Loqate.AddressResponse | null;
}
export interface IPersonInfo {
  title: null | string;
  firstName: string;
  lastName: string;
  email: string;
  emailConfirm: string;
  dob: string;
}
export type IChildCourses = {
  [key in CourseTypes]: ICourseIdPair | null
};
export interface IChild {
  firstName: null | string;
  lastName: null | string;
  gender: null | 'M' | 'F';
  dob: null | string;
  courses: IChildCourses;
  skipIA: {
    maths?: boolean | null;
  };
}
// value from store
export interface IDocument {
  id: string;
  uploadProgress: null | number;
  uploaded: null | number;
  url: null | string;
  uploadAt: null | string;
  size: number;
  uploadError: Error | string | null;
  uploading: boolean;
}
export interface IDocumentPackDocument extends IPdfDocumentDescription {
  paymentIndex?: number;
  originalId: string;
}
// value from getter
export interface IDocumentGetter extends IDocumentPackDocument, IDocument {
  /** The index position within the document state for updating values */
  index: number;
}

export interface IItemPurchased {
  /** Some id */
  id: string;
  /** amount for item */
  amountInCents: number;
  /** Number of this item purchased */
  quantity: number;
  /** Is this a monthly amount */
  monthly: boolean;
  /** amountFor Contact Amount only applies for monthly pricing */
  amountInCentsForContractAmount: number;
}

export type IItemPurchasedData = (Pick<IItemPurchased, 'id' | 'amountInCents'> & { invoiceDescription: string, description: string })[]

export type IrisStateFinance = {
  /** holder for details for another party to pay deposits */
  depositAltPayerInfo: IPersonInfo & IAddressInfo;
  /** ways to pay for the exemplar programme */
  paymentMethod: PaymentMethods;
  /** months deposit required for PAYG 1 2 3 */
  monthsDeposit: TPaygMonthsDepositOptions;
  /** term for finance OR the PAYG 3 & 6 month options also 1-5 year options for finance */
  monthsTerm: null | 3 | 6 | 12 | 24 | 36 | 48 | 60;
  decision: null | 'FA' | 'FD' | 'DFA' | 'FR';
  referred: boolean;
  selfDeclaration: null | string;
  complianceComments: string;
  proofID: null | boolean;
  proofIncome: null | boolean;
  pricingDate: null | string;
  /** Are going to cancel PAYG 3 Month deposit at time of sale OR CP12 sale type */
  immediateNoticeProvided: boolean;
  /** recurly payment method id if selected null means use direct debit */
  recurlyDDPaymentMethodId: string | null;
  dd: {
    ddAltPayerInfo: IPersonInfo & IAddressInfo;
    ddAltPayer: TDirectDebitAltpayer;
    accountHolder: null | string;
    bankName: null | string;
    bankAddress: null | string;
    sortCode: null | string;
    accountNumber: null | string;
    confirmation?: null | boolean;
  };
};
export interface IrisState {
  /** Flag to indicate we are upgrading an account */
  upgradingAccount: boolean;
  /** iris in documentation updating only mode - defaults to false
   * Basically bypasses all api calling
  */
  documentationOnlyMode: boolean;
  /** force a back link in the ui if set */
  backLink?: string;
  /** Assessment System Identifier, used to tag the assessments system upon account creation call in iris */
  assessmentId: string | null;
  // threej: ThreeJModule; TODO somehow document this module
  payments: PaymentModuleState;
  instalments: InstalmentState
  initialTime: string;
  privacyPolicyAccept: boolean;
  privacyPolicyAcceptDate: string | null;
  securekey: string;
  branchOffice: string;
  userId: number;
  regHitId: number;
  regModelMap: IRegModelMap;
  consultant: string;
  position: null | IPosition;
  timeDifference: number;
  apiException: null | Error;
  authenticated: boolean;
  recurlyResponse?: IAccountResponse;
  familyInformation: {
    alternativeEmail: string;
    alternativeEmailConfirm: string;
    mobilePhone: string;
    sendUnlockCodeAsPlainMessage: boolean | 'email';
    homePhone: string;
    workPhone: string;
    sendLoginDetailsToMobilePhone: boolean;
    authorisedPersons: string;
  } & IPersonInfo;
  altPayer: TAltPayer;
  altPayerInformation: {
    mobilePhone: string;
  } & IPersonInfo & IAddressInfo;
  courseSelections: Record<CourseTypes, ICourseIdPair[]>;
  address: {
    state: null | TStates;
  } & IAddressInfo;
  finance: IrisStateFinance;
  createAccountResult: null | IResponseCreateAccount;
  completionCertificateData: null | IResponseCompletionCertificate;
  children: IChild[];
  submitting: boolean;
  step: number;
  submitMessage: null | string;
  unlockCode: null | string;
  flashMessage: null | string;
  documents: IDocument[];
  savedFamilyInfo: null | string;
  forceLogoutOnCompletion: boolean;
  /** List of promo codes in UPPER CASE as per constants */
  promoCodes: string[];
  /** flag to indicate if the sms to be sent after the video presentation for LTL sales */
  ltlExtraConfirmations: boolean;
  defaultOnPromoCodesApplied: boolean;
  capSalesPrice5k: boolean;
  /** Allow APS Subscription via stripe plan */
  allowApsSubscriptions: boolean;
  videoConfirmation: {
    startTime: null | string;
    endTime: null | string;
    progress: number;
    accept: null | string;
  };
  otherItemsPurchased: IItemPurchased[];
  /** global discount percentage eg 10 for 10% */
  discount: number;
  /** global discount dollar amount applied after discount */
  discountAmount: number;
  /** Payg 50 promo code flag */
  paygFifty: boolean;
  /** show the stripe subscription UI in please of the old manual Direct Debit UI */
  stripeSubscriptionEnabled: boolean;
  /** new fixed subscription model only */
  fixedSubscriptionModelEnabled: boolean;
  /** force 55 aps6 price with 1 month deposit only */
  bf90hack: boolean;

  /** enable recurly direct debit */
  recurlyDirectDebit: boolean;

  /** recurly api failure */
  recurlyApiLastError: null | string;

  /** created recurly account id */
  recurlyAccountId: null | string;

  /** has the recurly bypass been used */
  recurlyByPassUsed: boolean

  /** dynamics id if chosen */
  dynamicsPresentationId: null | string;

  /** direct debit isn't able to be completed on the night */
  directDebitUnableToBeCompletedOnNight: boolean;

  /** allow overriding of prices to be used in constant functions */
  priceOverride: number | null;

  /** SMIS - for SP12 this is a boolean to trigger the initial deposit to be a single month only, default is false */
  singleMonthInitialSubscription: boolean;

  /** new signing process confirmation id */
  newSigningProcessConfirmationId: null | string;

  /** title override */
  titleOverride: null | string

  /** next sequence number for subid */
  subscriberId: null | number
  /** forced pickup sale */
  forcePickupSale: boolean
  /** phase 2 pilot flag */
  phase2pilot: boolean
  bypassPayment: boolean
  /** override the number of sessions for estia labs plans */
  numberOfSessionsOverride: null | number
  /** numberOf months initial payment is for plans - defaults to 1 */
  monthsInitialPayment: number
  /** override the amount for months initial payment */
  overrideInitialPaymentAmount: number | null
}
/**
 * Store initialisation parameters.
 * If the initialStoreState is set the a few of the following parameters are not required
 */
export interface IStoreParams {
  // required params not used by store
  createIrisApi(store: IrisStore): Api;
  persistedKey?: string;
  /** iris state which overwrites the following params if set */
  initialStoreState?: Partial<IrisState>;
  /** some other parameters which are put into state but overridden by initial State if set */
  regHitId?: number;
  regModelMap?: IRegModelMap;
  consultant?: string;
  userId?: number;
  branchOffice?: string;
  authenticated?: boolean;
  forceLogoutOnCompletion?: boolean;
  irisDocumentsBucket: string;
}
export interface DropDownOption<T = any> {
  text: string;
  value: T;
}
export type IModuleCounts = {
  mathsWithoutRevision: number;
  literacy: number;
  reading: number;
  mathsRevision: number;
  maths: number;
  total: number;
  totalWithoutRevision: number;
  totalForFinanceCalculations: number;
};
export interface IDateRangePair {
  validFrom?: string | Moment | Date;
  validTo?: string | Moment | Date;
}
export type ICourseSet<T> = {
  [key in CourseTypes]: T;
};
export type PayerPossibleSigners<T> = {
  [key in TAltPayerNames]: T;
};
export type DocumentSignerDictionary<T> = {
  [key in TSignerNames]: T;
};
export type PartialNullable<T> = {
  [P in keyof T]: T[P] | null;
};
/**
 * This just helps strong type the 2nd arguments to all getters
 */
export type IrisGetters = {
  dataForCrmConversion: IIrisAccount
  newSubscriptionType: SubscriptionType
  dataForGenerateUnlockCodeApi: IRequestGenerateUnlockCode;
  dataForUpdateAccountApi: IRequestUpdateAccount;
  dataForUnlockApi: IRequestValidateUnlockCode;
  dataForCreateApi: IRequestCreateAccount;
  dataForFinanceApi: IRequestUpdateFinanceData;
  dataForStoreIncompleteFinanceApi: IRequestStoreIncompleteFinance;
  dataForDocumentsApi: IRequestUpdateDocuments;
  buildPdfDocumentBlobPromise(index: any): any;
  dataForCompletionCertificateDataApi: number;
  dataForCompleteFinanceDeclineApi: IRequestCompleteFinanceDecline;
  dataForSendDocumentsToCustomerApi: IRequestSendDocumentsToCustomer;
  dataForCancelIncompleteAccountApi: IRequestCancelIncomplete
  dataForRecurlyCreate: IAccount
  dataForRecurlyCreateAccount: IAccountCreation
  moment(): moment.Moment;
  moment(altTime: Date | Moment | string): moment.Moment;
  consultant: string;
  contractAmount: number;
  altPayerNameOptions: PayerPossibleSigners<DropDownOption<TDirectDebitAltpayer>>;
  depositPayerNameOptions: DropDownOption<TDepositAltPayer>[];
  directDebitPayerNameOptions: DropDownOption<TDirectDebitAltpayer>[];
  finalSaleType: Exclude<PaymentMethods, 'APS_SUBSCRIPTION'>;
  documentSignerNames: DocumentSignerDictionary<string>;
  documentPackById: Record<string, IDocumentGetter>;
  documentsById: Record<string, IDocumentGetter>;
  documentPack: IDocumentGetter[];
  documents: IDocumentGetter[];
  altPayer: boolean;
  altPayerDirectDebit: TDirectDebitAltpayer;
  altPayerDirectDebitInfo: IPersonInfo & IAddressInfo;
  subscriptionUnitPriceCents: number;
  monthlyInstallment?: number;
  monthlyInstallmentFn(monthsDeposit: number): number | undefined;
  lastInstallmentAmount?: number;
  stateList: {
    code: TStates;
    name: string;
  }[];
  paygRevisionPricing: boolean;
  moduleCount: IModuleCounts;
  irisPricing: IrisPricingModel;
  irisRevisionPricing: IrisPaygRevisionPricingOption[];
  modulePrice: number;
  allDocumentsSigned: boolean;
  saleValue: number;
  coursesList: ICourseSet<ICourseMap[]>;
  financeAmount: number;
  initialTime: Moment;
  selectedCoursesLists: ICourseSet<ICourseMap[]>;
  goodsDescription: string;
  childrenDefaultedCourseSelections: PartialNullable<ICourseSet<ICourseIdPair>>;
  // document getters
  fieldsForDocuments: Record<string, Record<string, string>>;
  position: IPosition;
  ltlExtraConfirmations: boolean;
  ltlExtraConfirmationMobilePhone: string;
  promoCodeData(code: string, options?: {
    ignoreExtraChecks?: boolean;
  }): IrisPromoCodes | undefined;
  promoCodes: IrisPromoCodes[];
  // fields for certs
  fieldsForCertificate: Record<string, string>;
  paymentMethods: DropDownOption<string>[];
  // stuff for deposit payments or null if ltl / aps etc...
  paymentAmountRequiredNow: number | null; // basically only set for PAYG/CASH types
  getInfoForPayerType(payerType: TAltPayerNames): IPersonInfo & IAddressInfo

  // calculate the deposit for 3md
  paygDepositAmountFn(months: TPaygMonthsDepositOptions): number;
  itemsAvailableForSale: IItemPurchasedData;
  otherItemsPurchased: Array<IItemPurchased & { invoiceDescription: string }>;
  monthlyTotalOfOtherItemsPurchased: number;
  totalOfOtherItemsPurchased: number;
  saleValueWithoutExtras: number;
  discount: (amount: number, factor: 0 | 1 | 100) => number;
  /** Payment type is monthly payments type false is LTL type */
  isSubscriptionType: boolean;
  /** enable stripe direct debit option */
  isStripeDirectDebit: boolean;
  /** does not need direct debit account information */
  hasDirectDebit: boolean;
  /** collect first DD date, but bypass the collection of the account information */
  requiresDirectDebitFirstDate: boolean;
  /** minimum number of modules (fixed at 6) */
  minimumModules: number;
  /** Are going to cancel PAYG 3 Month deposit at time of sale OR new plans allowing it (eg CP12) */
  immediateNoticeProvided: boolean;
  /** is this a subscription plan December 2021 type */
  isSubscriptionPlanDec2021: boolean;
  /** minimum term in months for the current plan - 0 if no term */
  minimumTerm: number;
  /** has tutor helpline */
  hasTeacherHelpline: boolean;
  /** current new iris pricing Plan if code matches any code in list */
  currentNewPricingPlan?: EstiaLabsPricingModel;
  /** full company name eg Exemplar Education or Estia Tuition */
  companyName: string;
  /** short company name eg Exemplar or Estia */
  shortCompanyName: string;
  /** sale confirmation data to do new signing process */
  dataForConfirmationApi: SaleConfirmationData;
  /** minimum length if immedicate notice is given on the night */
  minimumNoticeTerm: number
} & PaymentModuleRootGetters & InstalmentModuleRootGetters

export class IrisStore extends Store<IrisState> {
  declare irisApi: Api
  declare nestApi: NestJSApi
  declare $feathers: Application<ServiceTypes>
  declare getters: IrisGetters
}

declare module 'vuex' {
  interface Store<S> {
    irisApi: Api;
    nestApi: NestJSApi
  }
}
